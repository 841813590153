import {functions, httpsCallable} from "@/firebase/firebaseConfig";
import {Subscription} from "@/lib/types";

const starterProductId = process.env.NEXT_PUBLIC_STRIPE_STARTER_PRODUCT_ID
const proProductId = process.env.NEXT_PUBLIC_STRIPE_PRO_PRODUCT_ID
const businessProductId = process.env.NEXT_PUBLIC_STRIPE_BUSINESS_PRODUCT_ID

export function retrieveSubscription(uid: string): Promise<Subscription> {
    const retrieveSubscription = httpsCallable(functions, 'subscription-retrieve')
    return retrieveSubscription({uid}).then(result => {
        return result.data as Subscription
    }).catch(error => {
        console.error('Error retrieving subscription', error)
        throw new Error('Error retrieving subscription')
    })
}

export function hostedVideoCalculation(subscription: Subscription) {
    if (subscription.type === null || subscription.status !== 'active') {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 15mins`
    } else if (subscription.id === starterProductId) {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 60mins`
    } else if (subscription.id === proProductId) {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 240mins`
    } else if (subscription.id === businessProductId) {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 6000mins`
    } else {
        return 'N/A'
    }
}

export function hostedVideoCalculationInPercentage(subscription: Subscription) {
    if (subscription.type === null || subscription.status !== 'active') {
        return Math.ceil((subscription.hostedVideoUsage / 15) * 100);
    } else if (subscription.id === starterProductId) {
        return Math.ceil((subscription.hostedVideoUsage / 60) * 100);
    } else if (subscription.id === proProductId) {
        return Math.ceil((subscription.hostedVideoUsage / 240) * 100);
    } else if (subscription.id === businessProductId) {
        return Math.ceil((subscription.hostedVideoUsage / 6000) * 100);
    }
}

export function calculateCost(subscription: Subscription): number {
    if (subscription.type === null || subscription.status !== 'active') {
        return 0.2
    } else {
        return 0
    }
}