import { auth } from './firebaseConfig'; // Import the app from firebaseConfig.ts
import { applyActionCode, createUserWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updatePassword, User, UserCredential } from "firebase/auth";
import { useRouter } from "next/navigation";
import React from "react";
import { sendEmailVerification, updateProfile } from "@firebase/auth";
import { updateUserProfile, UserProfile } from "@/lib/UserProfile";
import { logAnalyticsEvent } from "@/firebase/firebaseAnalytics";

/**
 * Get the current user
 */
export const currentUser = function (): User | null {
  return auth.currentUser;
};

/**
 * Sign up a user with email and password
 * @param email
 * @param password
 * @param displayName
 * @param referralMethod
 */
export const signUp = async function ({
  email,
  password,
  displayName,
  referralMethod
}: {
  email: string;
  password: string;
  displayName: string;
  referralMethod: string;
}) {
  let credential: UserCredential | null = null,
    error: any = null;
  return createUserWithEmailAndPassword(auth, email, password).then(cred => {
    credential = cred;

    // Update the user auth profile with the display name
    updateProfile(credential.user, {
      displayName: displayName
    }).then(() => {
      // Update the user profile in Firestore
      updateUserProfile({
        uid: cred.user.uid,
        displayName,
        email,
        referralMethod,
        onboardEditScreen: false,
        onboardPricing: false
      } as UserProfile);
    }).then(() => {
      // Send a verification email to the user
      sendVerificationEmail();
    });
    return {
      credential,
      error
    };
  }).catch(e => {
    const code = e.code;
    let message = e.message;
    if (code === 'auth/email-already-in-use') {
      message = "The email address is already used by another account.";
    } else if (code === 'auth/invalid-email') {
      message = "The email address is not valid.";
    } else if (code === 'auth/operation-not-allowed') {
      message = "New signups are not presently enabled.";
    } else if (code === 'auth/weak-password') {
      message = "The password is too weak. Strengthen the password and try again.";
    }
    error = e;
    error.message = message;
    return {
      credential,
      error
    };
  });
};

/**
 * Sign in a user with email and password
 * @param email
 * @param password
 */
export const signIn = async function (email: string, password: string) {
  let credential: UserCredential | null = null,
    error: any = null;
  return signInWithEmailAndPassword(auth, email, password).then(cred => {
    credential = cred;
    return {
      credential,
      error
    };
  }).catch(e => {
    const code = e.code;
    let message = e.message;
    if (code === 'auth/invalid-credential') {
      message = "The email address or password is incorrect.";
    } else if (code === 'auth/invalid-email') {
      message = "The email address is not registered. Please sign up.";
    } else if (code === 'auth/user-disabled') {
      message = "The user corresponding to the given email has been disabled.";
    } else if (code === 'auth/user-not-found') {
      message = "There is no user corresponding to the given email.";
    } else if (code === 'auth/wrong-password') {
      message = "The password is invalid for the given email.";
    }
    error = e;
    error.message = message;
    return {
      credential,
      error
    };
  });
};
export const changePassword = async function (currentPassword: string, newPassword: string) {
  const user = currentUser();
  console.log('Changing password for user', user?.email, currentPassword, newPassword);
  if (user == null) {
    return Promise.reject(new Error('No user is signed in.'));
  }
  const credential = EmailAuthProvider.credential(user.email || '', currentPassword);
  return reauthenticateWithCredential(user, credential).then(userCredential => {
    console.log('Re-authenticated user', userCredential);
    return updatePassword(user, newPassword);
  }).catch(error => {
    console.log('Error re-authenticating user', error.code, error.message);
    const code = error.code;
    let message = error.message;
    if (code === 'auth/invalid-credential') {
      message = "The current password is incorrect.";
    } else if (code === 'auth/weak-password') {
      message = "The password should be at least 6 characters.";
    } else if (code === 'auth/user-disabled') {
      message = "The user corresponding to the given email has been disabled.";
    } else if (code === 'auth/wrong-password') {
      message = "The current password is incorrect.";
    }
    throw new Error(message);
  });
};
export const resetPassword = async function (email: string) {
  return sendPasswordResetEmail(auth, email).then(() => {
    return true;
  }).catch(error => {
    console.log('Error sending password reset email', error.code, error.message);
    const code = error.code;
    let message = error.message;
    if (code === 'auth/invalid-email') {
      message = "The email address is not valid.";
    } else if (code === 'auth/user-not-found') {
      message = "There is no user corresponding to the given email.";
    }
    throw new Error(message);
  });
};

/**
 * Logout a user. Use this a component and pass in the button or element you want to use to initiate logout
 * @param children
 * @constructor
 */
export const Logout = function Logout({
  children,
  onLogout
}: {
  children: React.ReactNode;
  onLogout?: () => void;
}) {
  const handleLogout = async () => {
    onLogout && onLogout();
    signOut(auth).then(() => {
      // Sign-out successful.
      logAnalyticsEvent('logout');
      console.log("Signed out successfully");
    }).catch(error => {
      // An error happened.
      console.log("Error signing out");
    });
  };
  return <span onClick={handleLogout}>
            {children}
        </span>;
};
export const getProfileInfo = function (user: User | null | undefined) {
  if (!user) {
    return {
      displayName: null,
      shortName: null,
      initals: null,
      email: null,
      photoURL: null,
      emailVerified: null,
      uid: null
    };
  }
  const shortName = user.displayName?.split(" ")[0];
  const initals = user.displayName?.split(" ").map(n => n[0]).join("");
  return {
    displayName: user.displayName,
    shortName,
    initals,
    email: user.email,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified,
    uid: user.uid
  };
};
export function sendVerificationEmail() {
  const user = currentUser();
  if (user) {
    // Redirect to the email verification page
    const actionCodeSettings = {
      url: `${window.location.origin}/email-verification`,
      handleCodeInApp: true
    };
    return sendEmailVerification(user, actionCodeSettings);
  } else {
    return Promise.reject("No user is signed in.");
  }
}
export async function handleEmailVerification(auth: any, oobCode: string) {
  try {
    await applyActionCode(auth, oobCode);
    console.log("Email verified");
  } catch (error) {
    console.log("Error verifying email", error);
  }
}
export function checkEmailVerified(user: User | null | undefined, refresh: () => void) {
  console.log('User', user);
  if (user && !user.emailVerified) {
    return user.reload().then(() => {
      refresh && refresh();
      console.log('User after reload', user);
      if (user && user?.emailVerified) {
        console.log("Email verified, refreshing token");
        return user.getIdToken(true).then(() => {
          return true;
        });
      } else {
        console.log("Email not verified");
        return Promise.resolve(false);
      }
    });
  } else if (user && user?.emailVerified) {
    return Promise.resolve(true);
  } else {
    return Promise.resolve(false);
  }
}