import React, { createContext, useContext, useEffect } from 'react';
import { app, functions, httpsCallable } from '@/firebase/firebaseConfig';
import { onAuthStateChanged, getAuth, User as FirebaseUser } from 'firebase/auth';
import { useRouter } from "next/navigation";
import { Subscription, UserPermissions } from "@/lib/types";
import { getUserPermissions } from "@/lib/UserPermissions";
import { useForceUpdate } from "@/hooks/useForceUpdate";
import { getTotalCredits } from "@/lib/Billing";
import { currentUser } from "@/firebase/firebaseAuth";
import { retrieveSubscription } from "@/lib/Subscription";
import { getUserProfile } from "@/lib/UserProfile";
//

const auth = getAuth(app);
export interface AuthContextInterface {
  user: FirebaseUser | null | undefined;
  permissions: UserPermissions | null | undefined;
  billingCredits: number | undefined | null;
  setBillingsCredits: (credits: number | undefined | null) => void;
  loading: boolean;
  forceUpdate: () => void;
  subscription: Subscription | null;
}
export const AuthContext = createContext<AuthContextInterface>({
  user: undefined,
  permissions: null,
  billingCredits: undefined,
  setBillingsCredits: () => {},
  loading: true,
  forceUpdate: () => {},
  subscription: null
});
export const useAuthContext = () => useContext(AuthContext);
export const AuthContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    forceUpdate,
    forceUpdateValue
  } = useForceUpdate();
  const [user, setUser] = React.useState<FirebaseUser | null | undefined>(undefined);
  const [permissions, setPermissions] = React.useState<UserPermissions | null | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [billingCredits, setBillingsCredits] = React.useState<number | undefined | null>(undefined); //
  const [subscription, setSubscription] = React.useState<Subscription | null>(null);
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
        console.log('Set user:', user);
      } else {
        setUser(null);
        console.log('Set user to null');
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Get the User's permissions on login or page load. Triggered when uid changes.
  useEffect(() => {
    if (user?.uid) {
      getUserPermissions().then(perm => {
        setPermissions(perm);
      });
    }
  }, [user?.uid, forceUpdateValue]);

  // Get and set billingCredits using useEffect
  useEffect(() => {
    if (user?.uid) {
      getTotalCredits().then(credits => {
        setBillingsCredits(credits);
        console.log('Fetched billing credits:', credits);
      }).catch(error => {
        console.error('Failed to fetch billing credits:', error);
        setBillingsCredits(0);
      });
      const fallbackGetBillingCredits = () => {
        if (user?.uid && !billingCredits) {
          getTotalCredits().then(credits => {
            setBillingsCredits(credits);
            console.log('Retried fetching billing credits and succeeded:', credits);
          }).catch(error => {
            console.error('Retried fetching billing credits and failed:', error);
          });
        }
      };

      // Retry getting billing credits after 10 seconds
      setTimeout(() => {
        fallbackGetBillingCredits();
      }, 10000);

      // Retry getting billing credits after 20 seconds
      setTimeout(() => {
        fallbackGetBillingCredits();
      }, 20000);
    }
  }, [user?.uid, forceUpdateValue]);

  // Get the subscription details
  useEffect(() => {
    if (user?.uid) {
      retrieveSubscription(user?.uid).then(subscription => {
        setSubscription(subscription);
      }).catch(error => {
        console.error('Failed to fetch subscription:', error);
      });
    }
  }, [user?.uid, forceUpdateValue]);
  return <AuthContext.Provider value={{
    user,
    permissions,
    billingCredits,
    setBillingsCredits,
    loading,
    forceUpdate,
    subscription
  }} data-sentry-element="unknown" data-sentry-component="AuthContextProvider" data-sentry-source-file="AuthContext.tsx">
            {children}
        </AuthContext.Provider>;
};

/**
 * Hook to get the current user
 * @param forceAuthRedirect - Force redirect to login page if user is not authenticated
 */
export function useUser(forceAuthRedirect = false) {
  const {
    user
  } = useAuthContext();
  const router = useRouter();
  React.useEffect(() => {
    if (user === null && forceAuthRedirect) {
      console.log('Redirecting user to login');
      router.push("/login");
    }
  }, [user]);
  return user;
}

/**
 * Hook to get the permissions for the current user
 */
export function usePermissions() {
  const {
    permissions
  } = useAuthContext();
  return permissions;
}

/**
 * Hook to enforce a permission with a redirect if the specified attribute is false
 * @param attribute
 * @param destination
 */
export function usePermissionsWithRedirect(attribute: string, destination: string) {
  const router = useRouter();
  const {
    permissions
  } = useAuthContext();
  React.useEffect(() => {
    console.log("Permissions", permissions);
    // @ts-ignore
    if (permissions && !permissions?.[attribute]) {
      router.push(destination);
    }
  }, [permissions]);
  return permissions;
}

/**
 * Hook to get the billingCredits for the current user
 */
export function useBillingCredits() {
  const {
    billingCredits,
    setBillingsCredits
  } = useAuthContext();
  return {
    billingCredits,
    setBillingsCredits
  };
}

/**
 * Hook to enforce admin permission with a redirect if the user is not an admin
 */
export function useAdminPermissions() {
  const {
    user,
    permissions
  } = useAuthContext();
  const router = useRouter();
  useEffect(() => {
    if (user === null) {
      router.push('/login');
    }
    if (permissions !== undefined && !permissions?.admin) {
      router.push('/video');
    }
  }, [user?.uid, permissions?.admin]);
}

/**
 * Non-hook function to get the current user and permissions
 * This should not be the primary way to get user and permissions as it is less efficient than using hooks
 * Only to be used when requiring this information outside the AuthContext
 * @return {Promise<{user: FirebaseUser | null | undefined, permissions: UserPermissions | null | undefined, isAdmin: boolean | undefined}>}
 */
export async function getUserAndPermissions() {
  const user = currentUser();
  const uid = user?.uid || '';
  const permissions = await getUserPermissions();
  const isAdmin = permissions?.admin;
  return {
    user,
    uid,
    permissions,
    isAdmin
  };
}

/**
 * Hook to get the current subscription
 */
export function useSubscription() {
  const {
    subscription
  } = useAuthContext();
  return {
    subscription
  };
}