import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage";
import {initializeFirestore} from "@firebase/firestore";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {connectFunctionsEmulator, getFunctions, httpsCallable} from 'firebase/functions';
import {getAnalytics, isSupported as isAnalyticsSupported} from "firebase/analytics";

// The web app's Firebase configuration configured from .env files
const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage();
const firestore = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
});
const provider = new GoogleAuthProvider()

const functions = getFunctions(app, "australia-southeast1");
if (process.env.NODE_ENV == "development") {
    // connectFunctionsEmulator(functions, "localhost", 5001);
}

const analytics = isAnalyticsSupported().then((isSupported) => {
    return isSupported ? getAnalytics(app) : null
});

export {
    app,
    auth,
    provider,
    storage,
    firestore,
    functions,
    httpsCallable,
    analytics,
};
